import * as React from "react"

import Footer from "../components/Footer.js"
import NewHeader from "../components/NewHeader.js"
import { Helmet } from "react-helmet"

import {FaTwitter} from 'react-icons/fa'

import {
    Box,
    Container,
    Button,
    IconButton,
    Heading,
    Text,
    HStack,
    Grid,
    GridItem,
    Collapse,
    Flex,
    Avatar,
    Image,
    Wrap,
    WrapItem,
    Center,
    List,
    ListItem,
    Link
} from "@chakra-ui/react"

 import FSSlogo from '../images/FSSlogo.png'
 import NYLPI from '../images/NYLPI.png'
 import NYCEJA from '../images/NYCEJA.png'
 import OpenPlans from '../images/OpenPlans.png'
 import ReinventAlbanyLogo from '../images/ReinventAlbanyLogo.png'
 import RidersAllianceLogo from '../images/RidersAllianceLogo.jpg'
 import RPAlogo from '../images/RPAlogo.jpg'
 import StraphangersCampaignLogo from '../images/StraphangersCampaignLogo.png'
 import StreetsPacLogo from '../images/StreetsPacLogo.png'
 import TALogo from '../images/TALogo.svg'
 import TransitCenterLogo from '../images/TransitCenterLogo.jpg'
 import TSTCLogo from '../images/TSTCLogo.svg'

import {
    EmailIcon
} from '@chakra-ui/icons'

const emailFieldSHeaderStyle = {
    fontSize: "md",
    color: "gray.400",
    height: "1.5em",
    //bg: "tomato",
    fontSize: ['xs', 'xs', 'sm', 'md']
}
const emailFieldContentStyle = {
    padding: "0px 20px 0px 20px",
    width: "auto",
    maxWidth: "100%",
    fontSize: "md",
    color: "gray.700",
    overflow: "break-word",
    textRendering: "optimizeLegibility",
    fontSize: ['xs', 'xs', 'sm', 'md']
}

const emailGridStyle = {
    gridRowGap: 2,
    gridColumnGap: 4,
    bg: "gray.100",
    //boxShadow: "md",
    rounded: "md",
    padding: "10px",
    display: "flex",
    flexFlow: "column wrap"
}

const peteLetterParagraphstyle = {
    padding: "0.4em 0 0.4em 0",
    overflowWrap: "break-word"
}

const emailStageStyle = {
    flexDirection: "row",
    flexWrap: "wrap",
    //boxShadow: "sm",
    //margin: "20px",
    //border: "2px dashed black",
    padding: "20px"
    //bg: "gray.400",
    //borderRadius: "10"
}

const orgs = [
    {
        name: "Families for Safe Streets",
        icon: FSSlogo,
        url: "https://www.familiesforsafestreets.org/"
    },
    {
        name: "New York Lawyers for the Public Interest",
        icon: NYLPI,
        url: "https://www.nylpi.org/"
    },
    {
        name: "New York City Environmental Justice Alliance",
        icon: NYCEJA,
        url: "https://nyc-eja.org/"
    },
    {
        name: "Open Plans",
        icon: OpenPlans,
        url: "https://www.openplans.org/"
    },
    {
        name: "Regional Plan Association",
        icon: RPAlogo,
        url: "https://rpa.org/"
    },
    {
        name: "Reinvent Albany",
        icon: ReinventAlbanyLogo,
        url: "https://reinventalbany.org/"
    },
    {
        name: "Riders Alliance",
        icon: RidersAllianceLogo,
        url: "https://www.ridersalliance.org/"
    },
    {
        name: "Straphangers Campaign",
        icon: StraphangersCampaignLogo,
        url: "https://www.straphangers.org/"
    },
    {
        name: "Streets PAC",
        icon: StreetsPacLogo,
        url: "https://www.streetspac.org/"
    },
    {
        name: "Transit Center",
        icon: TransitCenterLogo,
        url: "https://transitcenter.org/"
    },
    {
        name: "Transportation Alternatives",
        icon: TALogo,
        url: "https://www.transalt.org/"
    },
    {
        name: "Tri-State Transportation Campaign",
        icon: TSTCLogo,
        url: "https://www.tstc.org/"
    }
]

const preLetterParaStyle = {
    fontSize: "lg"
}

const contactURL = {
    //color: "white",
    //border: "2px solid white",
    textDecoration: "underline dotted"

}

const BIDEN_TWEET = {
    text: "With your climate agenda blocked, cities and states need to step up. NY did so, passing #CongestionPricing in 2019. But @SecretaryPete is blocking it for no good reason. Tell him: approve #CongestionPricing now.",
    name: "Biden",
    handle: "POTUS"
}
const BUTTIGIEG_TWEET = {
    text: "You promised a speedy review for NYC #congestionpricing. Instead, you’re delaying a plan that local officials legislated 3½ years ago, costing us over $10 million a day in time savings alone. Please stop meddling. Approve #CongestionPricing now.",
    name: "Buttigieg",
    handle: "SecretaryPete"
}

const BIDEN_LETTER = {
    name: "Joe Biden",
    to: "president@whitehouse.gov",
    cc: "info@goodtraffic.nyc",
    subject: "Approve NYC Congestion Pricing Now",
    text: [
            "Dear President Biden:",
            "With your climate agenda blocked in Congress, U.S. cities and states need to step up. New York did so, passing congestion pricing in 2019.",
            "But Transportation Secretary Pete Buttigieg stands in the way. His agency is making NY transit officials answer endless questions to prove what we already know: congestion pricing is good for the city’s health, safety, families, economy and climate.",
            "Mr. President, make Secretary Buttigieg stop. Tell him to APPROVE CONGESTION PRICING NOW."
        ],
    form: {
        title: "White House contact form",
        url: "https://www.whitehouse.gov/contact/"
    },
    emailText: "mailto:president@whitehouse.gov?cc=info@goodtraffic.nyc&subject=Approve%20Congestion%20Pricing%20Now%21&body=Dear%20President%20Biden%3A%0A%0AWith%20your%20climate%20agenda%20blocked%20in%20Congress%2C%20U.S.%20cities%20and%20states%20need%20to%20step%20up.%20New%20York%20did%20so%2C%20passing%20congestion%20pricing%20in%202019.%0A%0ABut%20Transportation%20Secretary%20Pete%20Buttigieg%20stands%20in%20the%20way.%20His%20agency%20is%20making%20NY%20transit%20officials%20answer%20endless%20questions%20to%20prove%20what%20we%20already%20know%3A%20congestion%20pricing%20is%20good%20for%20the%20city%E2%80%99s%20health%2C%20safety%2C%20families%2C%20economy%20and%20climate.%0A%0AMr.%20President%2C%20make%20Secretary%20Buttigieg%20stop.%20Tell%20him%20to%20APPROVE%20CONGESTION%20PRICING%20NOW.",
}

const BUTTIGIEG_LETTER = {
    name: "Pete Buttigieg",
    to: "DOTExecSec@dot.gov",
    cc: "info@goodtraffic.nyc",
    subject: "Approve NYC Congestion Pricing Now",
    text: [
            "Dear Secretary Buttigieg:",
            "You know congestion pricing is New York’s best traffic medicine. Why has your department put it on ice?",
            "Your environmental review should have taken five minutes, tops. Congestion pricing will reduce driving, cut gridlock and emissions, and make biking, walking, buses and trains better and easier. Fewer traffic jams helps drivers too. The benefits will fan out from Manhattan to every street, highway and neighborhood in the region.",
            "You promised a speedy review. But your DOT is hammering us with picayune questions — over 400 to date. The delays are piling up, with no end in sight.",
            "Every day New York doesn’t have congestion pricing costs us millions in lost travel time, not to mention dirtier air and dangerous streets.",
            "Stop holding it up. APPROVE NYC CONGESTION PRICING NOW."
        ],
    form: {
        title: "DOT contact form",
        url: "https://www.whitehouse.gov/contact/"
    },
    emailText: "mailto:DOTExecSec@dot.gov?cc=info@goodtraffic.nyc&subject=Pete%20Buttigieg%3A%20Approve%20Congestion%20Pricing%20Now%21&body=Dear%20Secretary%20Buttigieg%20%E2%80%94%20%0D%0DYou%20know%20that%20congestion%20pricing%20is%20New%20York%E2%80%99s%20best%20traffic%20medicine.%20So%20why%20has%20your%20department%20put%20it%20on%20ice%3F%20%0D%0DYour%20environmental%20review%20should%20have%20taken%20all%20of%20five%20minutes.%20Congestion%20pricing%20will%20diminish%20traffic%20volumes%2C%20cut%20gridlock%20and%20emissions%2C%20and%20make%20biking%2C%20walking%2C%20buses%20and%20trains%20safer%20and%20faster.%20Drivers%20%20benefit%20too%2C%20from%20fewer%20traffic%20jams.%20%0D%0DThe%20benefits%20will%20fan%20out%20from%20Manhattan%20to%20every%20street%2C%20highway%20and%20neighborhood%20in%20the%20region.%20%0D%0DYou%20promised%20a%20speedy%20review.%20But%20your%20DOT%20is%20barraging%20us%20with%20picayune%20questions%20%E2%80%94%20over%20400%20to%20date.%20The%20delays%20are%20piling%20up%2C%20with%20no%20end%20in%20sight.%20%0D%0DEvery%20day%20New%20York%20doesn%E2%80%99t%20have%20congestion%20pricing%20costs%20us%20millions%20in%20lost%20travel%20time%2C%20not%20to%20mention%20dirtier%20air%20and%20dangerous%20streets.%20%0D%0DI%20don%E2%80%99t%20know%20why%20you%E2%80%99re%20holding%20it%20up.%20Just%20stop%20it.%20APPROVE%20CONGESTION%20PRICING%20NOW.",

}

const officialContactList = [
    {
        title: "NYS Governor",
        handle: "GovKathyHochul",
        formURL: "https://www.governor.ny.gov/content/governor-contact-form",
    },
    {
        title: "NYS Mayor Eric Adams",
        handle: "NYCMayor",
        formURL: "https://www1.nyc.gov/office-of-the-mayor/mayor-contact.page",
    },
    {
        title: "NYC Public Advocate",
        handle: "JumaaneWilliams",
        formURL: "https://www.pubadvocate.nyc.gov/contact/",
    },
    {
        title: "NYC Comptroller Brad Lander",
        handle: "NYCComptroller",
        formURL: "https://comptroller.nyc.gov/about/contact-our-office/"

    },
]
const letterFullStyle = {
    maxWidth: "40%",
    minWidth: "300px",
    padding: "10px 0 10px 0",
    flexGrow: 1
    //shadow: "sm",
}


function LetterPage({src, contactForm}) {

    let formMsg = <>Email {src.name} at <Link sx={contactURL} href={`mailto:${src.to}`}>{src.to}</Link>. Or use the <Link sx={contactURL} href={src.form.url}>{src.form.title}.</Link></>
    let msg = <>Email {src.name} at <Link sx={contactURL} href={`mailto:${src.to}`}>{src.to}</Link>.</>

    return (
        <Container sx={letterFullStyle}>
            <Box padding="20px">
                <Text sx={preLetterParaStyle} >
                    {contactForm ? formMsg : msg}
                </Text>
            </Box>
            
                <Button
                    as="a"
                    colorScheme="pink"
                    leftIcon={<EmailIcon/>}
                    href={src.emailText}
                    //href="mailto:president@whitehouse.gov?cc=info@goodtraffic.nyc&subject=Approve%20Congestion%20Pricing%20Now%21&body=Dear%20President%20Biden%3A%0A%0AWith%20your%20climate%20agenda%20blocked%20in%20Congress%2C%20U.S.%20cities%20and%20states%20need%20to%20step%20up.%20New%20York%20did%20so%2C%20passing%20congestion%20pricing%20in%202019.%0A%0ABut%20Transportation%20Secretary%20Pete%20Buttigieg%20stands%20in%20the%20way.%20His%20agency%20is%20making%20NY%20transit%20officials%20answer%20endless%20questions%20to%20prove%20what%20we%20already%20know%3A%20congestion%20pricing%20is%20good%20for%20the%20city%E2%80%99s%20health%2C%20safety%2C%20families%2C%20economy%20and%20climate.%0A%0AMr.%20President%2C%20make%20Secretary%20Buttigieg%20stop.%20Tell%20him%20to%20APPROVE%20CONGESTION%20PRICING%20NOW."
                    width="100%"
                    margin="0 0 10px 0"
                >
                    Email {src.name}
                </Button>

            <Grid templateColumns='repeat(10, 1fr)' templateRows='repeat(3, 1fr)' sx={emailGridStyle}>
                <GridItem rowStart={1} sx={emailFieldSHeaderStyle}>
                    <Text>
                        To: 
                    </Text>
                </GridItem>
                <GridItem rowStart={1} colStart={2} sx={emailFieldContentStyle}>
                    <Text>{src.to}</Text>
                </GridItem>
                <GridItem rowStart={2} sx={emailFieldSHeaderStyle}>
                    <Text>
                        CC: 
                    </Text>
                </GridItem>
                <GridItem rowStart={2} colStart={2} sx={emailFieldContentStyle}>
                    <Text>{src.cc}</Text>
                </GridItem>
                <GridItem rowStart={3} sx={emailFieldSHeaderStyle}>
                    <Text>
                        Subject: 
                    </Text>
                </GridItem>
                <GridItem rowStart={3} colStart={2} sx={emailFieldContentStyle}>
                    <Text>{src.subject}</Text>
                </GridItem>
                <GridItem colStart={2} rowStart={4}  sx={emailFieldContentStyle}>
                    {
                        src.text.map( (para) => 
                            <Text sx={peteLetterParagraphstyle}>
                                {para}
                            </Text>
                        )
                    }
                </GridItem>
            </Grid>
        </Container>
    )
}

function TweetPage({src}) {
    return (
        <Container sx={letterFullStyle}>
            <Box padding="20px">
                <Text>Tweet {src.name} @{src.handle}:</Text>
            </Box>

                <Button
                    as="a"
                    bg="#1DA1F2"
                    color="white"
                    leftIcon={<FaTwitter />}
                    href={`https://www.twitter.com/${src.handle}`}
                    width="100%"
                    margin="0 0 10px 0"
                >
                    Tweet @{src.handle}
                </Button>
                
            <Box sx={emailGridStyle}>
                {src.text}
            </Box>
        </Container>
    )
}

const action = () => {
    return (
        <Box width="100%" bg="white">
            <Helmet><title>Good Traffic NYC — Take Action</title></Helmet>
            <NewHeader></NewHeader>
            <Box border="2px dashed black" margin="20px">
                <Flex
                    sx={emailStageStyle}
                >
                    <LetterPage src={BIDEN_LETTER} contactForm={true} />
                    <LetterPage src={BUTTIGIEG_LETTER} contactForm={false} />
                    
                    <Container>

                    </Container>
                </Flex>
                <Flex sx={emailStageStyle}>
                    <TweetPage src={BIDEN_TWEET}/>
                    <TweetPage src={BUTTIGIEG_TWEET} />
                </Flex>
            </Box>
            <Box bg="red.400">
                <Container padding="20px">
                    <Heading>
                        But don't stop there.
                    </Heading>
                    <Box padding="10px 0 10px 0">
                        <Text sx={preLetterParaStyle}>Write and tweet to local officials</Text>
                        <List padding="10px" spacing={4}>
                            {
                                officialContactList.map( (item) => (
                                    <ListItem>
                                        {item.title}: <Link sx={contactURL} href={`https://www.twitter.com/${item.handle}`}>@{item.handle}</Link> / <Link sx={contactURL} href={item.formURL}>Contact Form</Link>
                                    </ListItem>
                                ))
                            }
                            <ListItem>
                                To get your state legislators name and email, enter your residential address <Link sx={contactURL} href="https://www.aqeny.org/find-your-new-york-state-legislators/">here.</Link>
                            </ListItem>
                            <ListItem>
                            To get your NY City council member and borough president's name and email, enter your residential address <Link sx={contactURL} href="https://www.mygovnyc.org/">here.</Link>
                            </ListItem>
                        </List>
                    </Box>
                </Container>
            </Box>
            <Box bg="white" width="100%">
                <Container width="100%" padding="20px">
                        <Container padding="10px">
                            <Text sx={preLetterParaStyle}> Support the groups fighting for congestion pricing</Text>
                        </Container>
                        <Wrap align="center" justify="center" spacing="1em" padding="20px">
                            {
                                orgs.map( (org) => 
                                    <WrapItem>
                                        <a href={org.url} >
                                            <Avatar name={org.name} src={org.icon}/>
                                        </a>
                                    </WrapItem>
                                )
                            }
                        </Wrap>
                        <Container>
                            <Text>
                                All contributions are tax-deductible except to StreetsPAC.
                            </Text>
                            <Text>
                                When you make your donation, let them know it's to help them fight for congestion pricing.
                            </Text>
                            <Text>
                                Organizations were not asked for their approval for listing.
                            </Text>
                        </Container>
                </Container>
            </Box>
        <Footer>
        </Footer>
        </Box>
    )
}
export default action